import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import( '../views/ContactView.vue')
  },
  {
    path: '/legal',
    name: 'legal',
    component: () => import( '../views/LegalView.vue')
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: () => import( '../views/GalleryView.vue')
  },
  {
    path: '/find-us',
    name: 'findus',
    component: () => import( '../views/FindUs.vue')
  },
  {
    path: '/app-download',
    name: 'appdownload',
    component: () => import( '../views/AppDownloadView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(() => {
  window.scrollTo(0, 0)
})

export default router
