<template>
  <div :class="'d-flex my-4 w-100 flex-column align-items-center text-center ' + (dark ? null : 'text-white')">
    <h4 :class="(dark ? 'text-primary' : 'text-white')">{{subtitle}}</h4>
    <h2>{{ title }}</h2>
    <div :class="'mt-2 header-separator ' + (dark ? null : 'bg-white')"></div>

  </div>
</template>

<script>
export default {
  name: 'SectionHeader',
  props: {
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    dark: {
      type: Boolean,
      default: true
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>





</style>
