<template>
    <div class="testimonial">

        <img class="customerImg" alt="testimonial" :src="imageUrl">

        <h4>{{ name }}</h4>
        <small>{{ location }}</small>
        <p class="mt-3">{{ review }}</p>

        <img class="mt-4 ratingImg" :src="require('../assets/img/five-star-rating.webp')">
    </div>
</template>
   
<script>



export default {
    name: 'TestimonialItem',
    props: {
        name: {
            type: String,
            default: null
        },
        review: {
            type: String,
            default: null
        },
        location: {
            type: String,
            default: null
        },
        imageUrl: {
            type: String,
            default: null
        },
    }
}
</script>
   
   <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.testimonial {
    margin: 20px;
    position: relative;
    height: 350px;
    width: 300px;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
}

.testimonial .customerImg {
    position: absolute;
    padding:10px;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    height: 100px;
    width: 100px;
    background-color: white;
}

.testimonial .ratingImg {
    height:25px;
}


.testimonial p {
    text-align: center;
    font-size:15px;
}

@media (max-width:768px) {}
</style>
   