<template>
  <nav id="nav">
    <div class="nav-inner-container">
      <a class="orderopia-btn col nav-item" to="/order" data-orderopia-area="order"
        data-orderopia-business="party-box-telford" aria-label="order">Order Now</a>

      <router-link class="col nav-item" to="/gallery">Gallery</router-link>
      <div class="nav-logo-container col-auto">
        <router-link to="/"><img alt="logo" class="nav-logo" src="../assets/img/logo.webp"></router-link>
      </div>
      <router-link class="col nav-item" to="/find-us">Find Us</router-link>
      <router-link class="col nav-item" to="/contact">Contact</router-link>
      <button @click="toggled = !toggled" class="hamburger-menu-btn"><img alt="hamburger"
          src="../assets/img/hamburger-menu-icon.webp"></button>
    </div>
  </nav>

  <div :class="{ toggled: toggled }" id="flyout-nav">
    <div class="d-flex flex-column" style="height:100%">

      <div class="py-4 me-4 mt-3 mb-5 justify-content-between d-flex align-items-center">
        <div></div>
        <img id="flyout-logo" src="../assets/img/logo-lg.webp" />
        <button @click="toggled = !toggled" class="btn-icon">
          <img alt="close" src="../assets/img/close.webp">
        </button>
      </div>


      <div>
        <a class="orderopia-btn" to="/order" data-orderopia-area="order"
        data-orderopia-business="party-box-telford" aria-label="order">Order Now</a>
        <router-link @click="this.toggled = false" to="/gallery">Gallery</router-link>
        <router-link @click="this.toggled = false" to="/find-us">Find Us</router-link>

      </div>

      <div id="flyout-nav-bottom">
        <router-link @click="this.toggled = false" to="/contact">Contact</router-link>
      </div>
    </div>
    <div>

    </div>



  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  mounted() {

    // eslint-disable-next-line
    initOrderopia();
  },
  data() {

    return {
      //   images: [
      //   require("./assets/img/logo.webp")
      // ],
      toggled: false
    }
  }

}

import $ from 'jquery';


$(function () {
  $(document).scroll(function () {
    var $nav = $("#nav");
    $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
  });
});

//var toggled;

// function toggleMenu() {
//   toggled = !toggled;
//   var $flyout = $("#flyout-nav");

//   if(toggled) {
//     $flyout.addClass('toggled');
//   }
//   else {
//     $flyout.removeClass('toggled');
//   }
// }


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  text-align: center;
}

nav {
  margin-top: 25px;
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  display: flex;
  left: 0;
  right: 0;
  align-items: center;
  height: 120px;
  transition: all 200ms linear;
}

nav .nav-inner-container {
  margin: 0 auto;
  max-width: 800px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

nav a.router-link-exact-active {
  color: var(--primary);
}

nav.scrolled {
  background-color: var(--background) !important;
  height: 80px;
  transition: all 200ms linear;
  margin-top: 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0px;
}

nav.scrolled .nav-item {
  color: var(--foreground);
}

nav.scrolled .nav-logo {
  height: 120px;
  transition: all 200ms linear;
  margin-top: 75px;
}

nav a {
  text-decoration: none;
  color: white;
}

.nav-logo {
  height: 150px;
  transition: all 200ms linear;
}

.hamburger-menu-btn {
  background-color: transparent;
  border: none;
  outline: none;
  display: none;
  z-index: 99;
  cursor: pointer;
  height: 100%;
  width: 80px;
}

.hamburger-menu-btn img {
  height: 40px;
}

#flyout-nav {
  width: 50%;
  z-index: 9999;
  position: fixed;
  bottom: 0;
  left: -100%;
  top: 0;
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  transition: left 0.3s ease-in-out;
  max-width: 100%;
}

#flyout-nav * {
  display: block;
  text-align: start;
}

#flyout-nav.toggled {
  left: 0;
  transition: left 0.2s ease-in-out;
}

#flyout-nav a {
  height: 50px;
  font-size: 20px;
  text-align: center;
  line-height: 50px;
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

#flyout-logo {
  height: 35px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.nav-item {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
  color: white;
  border:none;
  outline:none;
  text-align: center;
  display: flex;
  align-items:center;
  font-size:16px;
  cursor:pointer;
transition:color 0.3s;
}

.nav-item.router-link-active {
  color:var(--accent);
}

.nav-item:hover {
color:rgba(243, 243, 243, 0.5);
}

#flyout-nav-bottom {
  background-color: var(--background-dark);
  width: 100%;
  flex: 1 1 auto;
}

@media (max-width:800px) {

  #flyout-nav {
    width: 100%;
  }

  nav {
    max-width: 100%;

  }

  nav .nav-item {
    display: none;
  }

  .nav-logo {
    margin-top: 10px;
  }


  nav.scrolled .hamburger-menu-btn img {
    filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(0%) contrast(80%)
  }

  .nav-logo-container {
    position: fixed;
    left: 50px;
    right: 50px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  nav.scrolled .nav-logo {
    height: 120px;
    transition: all 200ms linear;
    margin-top: 20px;
  }

  .hamburger-menu-btn {
    display: block;
  }




}
</style>
