


<template>
  <div  :class="{ 'top' : isTop}"  class="texture-separator d-flex">

    <img v-if="isTop" alt="separator" src="../assets/img/texture-separator-top.webp">
    <img v-if="isTop"  alt="separator" src="../assets/img/texture-separator-top.webp">
    <img v-if="isTop"  alt="separator" src="../assets/img/texture-separator-top.webp">
    <img v-if="isTop"  alt="separator" src="../assets/img/texture-separator-top.webp">
    <img v-if="isTop"  alt="separator" src="../assets/img/texture-separator-top.webp">
    <img  v-if="isTop" alt="separator" src="../assets/img/texture-separator-top.webp">
    <img  v-if="isTop" alt="separator" src="../assets/img/texture-separator-top.webp">
    <img  v-if="isTop" alt="separator" src="../assets/img/texture-separator-top.webp">

    <img v-if="!isTop" alt="separator" src="../assets/img/texture-separator-bottom.webp">
    <img v-if="!isTop"  alt="separator" src="../assets/img/texture-separator-bottom.webp">
    <img v-if="!isTop"  alt="separator" src="../assets/img/texture-separator-bottom.webp">
    <img v-if="!isTop"  alt="separator" src="../assets/img/texture-separator-bottom.webp">
    <img v-if="!isTop"  alt="separator" src="../assets/img/texture-separator-bottom.webp">
    <img  v-if="!isTop" alt="separator" src="../assets/img/texture-separator-bottom.webp">
    <img  v-if="!isTop" alt="separator" src="../assets/img/texture-separator-bottom.webp">
    <img  v-if="!isTop" alt="separator" src="../assets/img/texture-separator-bottom.webp">
  </div>
</template>

<script>
export default {
  name: 'RuggedSeparator',
  props: {
    isTop: {
      type: Boolean,
      default:true
    }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.texture-separator {
  display: flex;
  overflow-x:hidden;
  position: absolute;
  max-width: 100%;
}

.texture-separator img {
  height:50px;
}


.texture-separator {
  bottom: -12px;
}
.texture-separator.top {
  top:-12px;
  bottom:unset;
}


@media (max-width:800px) {

.texture-separator {
  max-width: 100%;
}

}

</style>
